function isTicket(ticket: any): ticket is ITicket {
  if (!ticket) {
    return false;
  }
  return 'ID' in ticket;
}

function isEvent(event: any): event is IEvent {
  if (!event) {
    return false;
  }
  return 'ID' in event;
}

export const typechecker = {
  isTicket,
  isEvent,
};
