import { API } from '@utils';

export const createSession = async (sessionData: ISessionData) => {
  return API.post<ISession>(`Sessions`, sessionData);
};

export const getSessionById = async (sessionId: string) => {
  return API.get<ISession>(`Sessions/${sessionId}`);
};

/**
 * @deprecated This function is deprecated and will be removed in future versions.
 */
export const updateSession = async (sessionId: string, sessionData: ISessionData) => {
  return API.put<ISession>(`Sessions/${sessionId}`, sessionData);
};
