import { withErrorHandler } from './errorHandler';
import { BACKEND_ENDPOINT } from '../constants';

export class ApiError extends Error {
  constructor(reason: string, message: string) {
    super(message);
    this.name = reason;
  }
}

export const API = {
  get: withErrorHandler(async <T>(route: string, parameters?: Record<string, string | number | boolean>): Promise<T> => {
    let url = `${BACKEND_ENDPOINT}/api/${route}`;
    if (parameters) {
      const queryParameters = new URLSearchParams(parameters as any).toString();
      url += `?${queryParameters}`;
    }

    const response = await fetch(url);
    if (!response.ok) {
      const errorResponse: IApiError = await response.json();
      throw new ApiError(errorResponse.Reason, errorResponse.Message);
    }
    return API.parseResponse<T>(response);
  }),

  post: withErrorHandler(async <T>(route: string, body = {}): Promise<T> => {
    const response = await fetch(`${BACKEND_ENDPOINT}/api/${route}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorResponse: IApiError = await response.json();
      throw new ApiError(errorResponse.Reason, errorResponse.Message);
    }
    return API.parseResponse<T>(response);
  }),

  put: withErrorHandler(async <T>(route: string, body = {}): Promise<T> => {
    const response = await fetch(`${BACKEND_ENDPOINT}/api/${route}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorResponse: IApiError = await response.json();
      throw new ApiError(errorResponse.Reason, errorResponse.Message);
    }
    return API.parseResponse<T>(response);
  }),

  delete: withErrorHandler(async <T>(route: string): Promise<T> => {
    const response = await fetch(`${BACKEND_ENDPOINT}/api/${route}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      const errorResponse: IApiError = await response.json();
      throw new ApiError(errorResponse.Reason, errorResponse.Message);
    }
    return API.parseResponse<T>(response);
  }),

  parseResponse: async <T>(response: Response): Promise<T> => {
    // Allow receiving either JSON or plain text / empty response
    const responseText = await response.text();
    try {
      const jsonResponse = JSON.parse(responseText);
      return jsonResponse;
    } catch {
      return responseText as T;
    }
  },
};
