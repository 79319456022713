export function timeFormatter(date: Date): string {
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  if (minutes.length < 2) {
    minutes = '0' + minutes;
  }
  if (hours === '0') {
    hours = '00';
  }
  const stringTime = `${hours}:${minutes}`;
  return stringTime;
}
