import { DEBUG } from '../constants';

export function storeContext<T>(itemName: string, context: T) {
  try {
    if (typeof window !== 'undefined') {
      let storageString = JSON.stringify({
        context,
        exp: Date.now() + 60 * 1000 * 5 /* exp: now + 1000ms * 60s * 5m */,
      });
      if (!DEBUG) {
        storageString = window.btoa(window.unescape(encodeURIComponent(storageString)));
      }
      return sessionStorage.setItem(itemName, storageString);
    }
  } catch (error) {
    console.warn('incorrectly formatted context', itemName, error);
    console.error(error);
  }
}

export function getContext<T>(itemName: string): { context: T; exp: number } | undefined {
  try {
    if (typeof window !== 'undefined') {
      let contextString = sessionStorage.getItem(itemName);
      if (contextString) {
        if (!DEBUG) {
          contextString = window.atob(contextString);
        }
        return JSON.parse(contextString);
      } else {
        return undefined;
      }
    }
  } catch (error) {
    console.warn('incorrectly formatted context', itemName, error);
    return undefined;
  }
}
