export const formatValue = (value?: number, currency?: string): string =>
  value ? `${value >= 100 ? `${currency || ''}${value % 100 === 0 ? value / 100 : (value / 100).toFixed(2)}` : `${value}c`}` : '';

/**
 * Format mobile number while user types
 *
 * @param {string} value string that will be converted
 *
 * @returns {string} formatted mobile number (XXX) XXX-XXXX
 */
export const formatMobile = (value: any) => {
  let value_ = value.replaceAll(/\D/g, '').match(/1?(\d{0,3})(\d{0,3})(\d{0,4})/);

  value_ = value_[2] ? '(' + value_[1] + ') ' + value_[2] + (value_[3] ? '-' + value_[3] : '') : value_[1];

  return value_;
};

export const formatTimeslot = (timeslot: string) => {
  const hasDayNotation = timeslot.includes('.');
  if (!hasDayNotation) {
    return timeslot.slice(0, 5);
  }
  const day = timeslot[0];
  return (
    <>
      {timeslot.slice(2, 7)} <sup style={{ marginLeft: '3px' }}>+{day}</sup>
    </>
  );
};
