export const BACKEND_ENDPOINT = process.env.NEXT_PUBLIC_BACKEND_ENDPOINT || 'https://testlink.scopetickets.com';
export const CART_ID_COOKIE = 'st_cid';
export const DATE_FORMAT_SHORT = 'D MMMM';
export const DATE_FORMAT_LONG = 'dddd, D MMMM YYYY';
export const DEBUG = process.env.NEXT_PUBLIC_DEBUG || false;
export const ESCROW_EXPIRY_MINUTES = 9.95; // 3 sec less than 10 mins to have a safety margin to the API calculation
export const SESSION_ID_COOKIE = 'st_sid';
export const SESSION_COOKIE_EXPIRATION = 2 / 24; // 2h expressed as days
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY || 'pk_test_05DPgZ5HyftRNAZbt7RPH6tM';
export const DATEPICKERTYPE = {
  Calendar: 'calendar',
  List: 'list',
} as const;
