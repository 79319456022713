/**
 * Tests if a string is likely a valid email address. Note the email format is
 * very lenient, but it also accepts higher-unicode emails where most regex
 * solutions return a false negative.
 * @param {string} value - the value to test
 * @return {boolean} if the value matches formatting for an email address
 */
export const isValidEmail = (value?: string) => {
  if (value) {
    return /.+@.+\..+/i.test(value);
  }
  return false;
};

export const getColorQueryOfEvent = (event: IEvent | Record<string, never>) =>
  event?.ColourSecondary && event?.ColourBackground
    ? `&background_color=${event.ColourSecondary.split('#')[1]}&font_color=${event.ColourBackground.split('#')[1]}`
    : '';
