export const currencyLookup = (currency: string): string => {
  switch (currency ? currency.toLowerCase() : '') {
    case 'eur': {
      return '€';
    }
    case 'gbp': {
      return '£';
    }
    case 'usd': {
      return '$';
    }
    case 'cad': {
      return '$';
    }
    default: {
      return '';
    }
  }
};
