import { API } from '@utils';

export const createCart = async (body: ICreateCartRequest) => {
  return API.post<ICartResponse>(`Cart`, {
    // CalculateAmounts: true, // FIXME
    ...body,
  });
};

export const getCart = async (cartKey: string) => {
  return API.get<ICartResponse>(`Cart/${cartKey}`);
};

export const addToCart = async (cartKey: string, body: IUpdateCartRequest) => {
  return API.post<ICartAddItemResponse>(`Cart/${cartKey}/Items`, {
    ...body,
  });
};

export const removeFromCart = async (cartKey: string, cartItemKey: string) => {
  return API.delete<ICartResponse>(`Cart/${cartKey}/Items/${cartItemKey}`);
};

export const addPromoCodeToCart = async (cartKey: string, code: string) => {
  return API.put<ICartResponse>(`Cart/${cartKey}/PromoCode`, {
    PromoCode: code,
  });
};

export const deletePromoCodeFromCart = async (cartKey: string) => {
  return API.delete<ICartResponse>(`Cart/${cartKey}/PromoCode`);
};

export const saveBillingDetails = async (cartId: string, body: IBillingDetailsRequest) => {
  return API.post<IBillingDetailsResponse>(`Cart/${cartId}/Buyer`, body);
};

export const convertCartToTransaction = async (cartId: string) => {
  return API.post<IConvertCartTransactionResponse>(`Transactions/FromCart`, {
    CartKey: cartId,
  });
};

export const createPaymentIntent = async (cartId: string) => {
  return API.post<ICreatePaymentIntentResponse>(`Cart/${cartId}/Payment`);
};

export const deleteCart = async (cartId: string) => {
  return API.delete(`Cart/${cartId}`);
};

export const getTransaction = async (transactionId: number) => {
  return API.get<ITransactionResponse[]>(`AttendeeTicket/GetByTransaction?transaction_id=${transactionId}`);
};
