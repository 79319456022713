import { API } from '@utils';

export const getTicket = async (ticketId: number) => {
  return API.get<ITicket>(`Ticket/Get/${ticketId}`);
};

export const checkPromoCode = async (eventId: string | number, promoCode: string) => {
  return API.get<IPromoCodeResponse>(`PromoCode/Check?code=${promoCode}&event_id=${eventId}`);
};

export const getQrCode = async (attendeeTicketId: string, foreground: string, background: string) => {
  return API.get<string>(
    `Utils/GetQrCodeAsSVG?content=${attendeeTicketId}&foreground=${foreground}&background=${background}&drawQuietZone=false`
  );
};
