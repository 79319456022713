import type { PaymentIntent } from '@stripe/stripe-js';
import { useAtom } from 'jotai';
import { RESET, atomWithStorage } from 'jotai/utils';
import { type Dispatch, type SetStateAction, createContext, useContext, useState, useEffect } from 'react';

import { deleteCart, deletePromoCodeFromCart } from '@apiCalls';
import { getContext } from '@utils';

interface ITicketState {
  tickets?: ITicket[] | ITicketSummary[];
  billingDetails?: IBillingDetails;
  purchasedTickets?: IAttendeeTicket[];
  paymentIntent?: PaymentIntent;
}

interface IAppContext {
  cartId?: string;
  setCartId: Dispatch<SetStateAction<string | undefined>>;
  cartItemIds: string[];
  setCartItemIds: Dispatch<SetStateAction<string[]>>;
  event: IEvent;
  setEvent: Dispatch<SetStateAction<IEvent>>;
  eventSeries?: IEventSeries;
  setEventSeries: Dispatch<SetStateAction<IEventSeries | undefined>>;
  escrowExpiryTime?: Date;
  setEscrowExpiryTime: Dispatch<SetStateAction<Date | undefined>>;
  promoCode?: string;
  setPromoCode: Dispatch<SetStateAction<string | undefined>>;
  ticketDetails: ITicketState;
  setTicketDetails: Dispatch<SetStateAction<ITicketState>>;
  timeslot?: IEventTimeslot;
  setTimeslot: any;
  resetCart: () => void;
  resetContext: () => void;
  resetPromoCode: () => void;
}

const emptyEventState: IEvent = {
  ID: 0,
  Name: '',
  VenueID: 0,
  Starts: '',
  Ends: '',
  Date: '',
  OrganisationID: 0,
  Background: '',
  Backgrounds: [],
  ColourPrimary: '',
  ColourSecondary: '',
  ColourBackground: '',
  ColourDetail: '',
  Title: '',
  Cover: '',
  VenueName: '',
  Permissions: {
    ID: 0,
    StaffID: 0,
    OrganisationID: 0,
    EventID: 0,
    ViewUptake: false,
    ViewFinancial: false,
    DoAdmissions: false,
    DoPosPayments: false,
    IsAdmin: false,
  },
  BrokerID: 0,
  SystemColour: '',
  Capacity: 0,
  Description: '',
  OrganisationName: '',
  OrganisationLogo: '',
  Organisation: {
    ID: 0,
    Name: '',
    Logo: '',
    StripeID: '',
    FeeNominal: 0,
    FeeRate: 0,
    PayworksID: '',
    PayworksKey: '',
    LegalName: '',
    Key: '',
    BrokerID: 0,
  },
  EventInformation: [],
  LocalStartTime: '',
  LocalEndTime: '',
};

const emptyTicketState: ITicketState = {
  tickets: [],
  billingDetails: undefined,
  paymentIntent: undefined,
  purchasedTickets: undefined,
};

export const initialEventState = () => {
  const event = getContext<IEvent>('event');
  if (event && event.context) {
    return Date.now() < event.exp ? event.context : emptyEventState;
  } else {
    return emptyEventState;
  }
};

export const initialTicketState = () => {
  const ticket = getContext<ITicketState>('ticket');
  if (ticket && ticket.context) {
    return Date.now() < ticket.exp ? ticket.context : emptyTicketState;
  } else {
    return emptyTicketState;
  }
};

const AppContext = createContext<IAppContext>({
  cartId: undefined,
  setCartId: state => console.warn('no cartId provider', state),
  cartItemIds: [],
  setCartItemIds: state => console.warn('no cartItemIds provider', state),
  event: initialEventState(),
  setEvent: state => console.warn('no event provider', state),
  eventSeries: undefined,
  setEventSeries: state => console.warn('no eventSeries provider', state),
  escrowExpiryTime: undefined,
  setEscrowExpiryTime: state => console.warn('no escrow provider', state),
  promoCode: undefined,
  setPromoCode: state => console.warn('no promoCode provider', state),
  ticketDetails: initialTicketState(),
  setTicketDetails: state => console.warn('no ticket provider', state),
  timeslot: undefined,
  setTimeslot: () => console.warn('no timeslot provider'),
  resetCart: () => console.warn('no resetCart provider'),
  resetContext: () => console.warn('no resetContext provider'),
  resetPromoCode: () => console.warn('no resetPromoCode provider'),
});

export const useAppContext = (): IAppContext => useContext(AppContext);

const cartIdAtom = atomWithStorage<string | undefined>('cartId', undefined);
const cartItemIdsAtom = atomWithStorage<string[]>('cartItemIds', []);
const eventAtom = atomWithStorage<IEvent>('event', initialEventState());
// NOTE: SSR presents challenges: https://jotai.org/docs/utilities/storage#server-side-rendering
const eventSeriesAtom = atomWithStorage<IEventSeries | undefined>('eventSeries', undefined);
const escrowExpiryTimeAtom = atomWithStorage<Date | undefined>('escrowExpiryTime', undefined);
const promoCodeAtom = atomWithStorage<string | undefined>('promoCode', undefined);
const ticketDetailsAtom = atomWithStorage<ITicketState>('ticketDetails', initialTicketState());
const timeslotAtom = atomWithStorage<IEventTimeslot | undefined>('timeslot', undefined);

export const AppContextProvider = ({ children }: { children?: any }) => {
  const [cartId, setCartId] = useAtom(cartIdAtom);
  const [cartItemIds, setCartItemIds] = useAtom(cartItemIdsAtom);
  const [event, setEvent] = useAtom(eventAtom);
  const [eventSeries, setEventSeries] = useAtom(eventSeriesAtom);
  const [escrowExpiryTime, setEscrowExpiryTime] = useAtom(escrowExpiryTimeAtom);
  const [promoCode, setPromoCode] = useAtom(promoCodeAtom);
  const [ticketDetails, setTicketDetails] = useAtom(ticketDetailsAtom);
  const [timeslot, setTimeslot] = useAtom(timeslotAtom);

  const resetContext = () => {
    setCartId(RESET);
    setCartItemIds(RESET);
    setEvent(RESET);
    setEventSeries(RESET);
    setEscrowExpiryTime(RESET);
    setTicketDetails(RESET);
    setTimeslot(RESET);
  };

  const resetCart = async () => {
    if (cartId) {
      await deleteCart(cartId);
    }
    setCartId(RESET);
    setCartItemIds(RESET);
    setTicketDetails(RESET);
  };

  const resetPromoCode = async () => {
    if (!promoCode) {
      return;
    }
    setPromoCode(RESET);
    if (cartId) {
      await deletePromoCodeFromCart(cartId);
    }
  };

  return (
    <AppContext.Provider
      value={{
        resetCart,
        resetContext,
        resetPromoCode,
        cartId,
        setCartId,
        cartItemIds,
        setCartItemIds,
        event,
        setEvent,
        eventSeries,
        setEventSeries,
        escrowExpiryTime,
        setEscrowExpiryTime,
        promoCode,
        setPromoCode,
        ticketDetails,
        setTicketDetails,
        timeslot,
        setTimeslot,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
